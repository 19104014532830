
import useHome from "@/composables/useHomePage";
import useMessageTip from "@/composables/useMessageTip";
import { store } from "@/store";
import { MutationType } from "@/store/mutation-types";
import { Close } from "@element-plus/icons";
import { computed, defineComponent, onUnmounted, PropType, watch } from "vue";
import { ref } from "vue";
import useGetEmployee from "./composables/getEmployee";
export default defineComponent({
  name: "employeeManagement",
  components: { Close },
  props: {
    list: {
      default: () => [],
      type: Array as PropType<Array<any>>
    }
  },
  emits: ["refresh"],
  setup(props, { emit }) {
    const treeRef = ref();
    const keywordTreeRef = ref();
    const { tipMessage } = useMessageTip();
    const { linkToPage } = useHome();

    const filterNode = (value: string, data: Record<string, any>) => {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    };

    watch(
      () => store.state.addMemberVisible,
      (newVal) => {
        if (newVal) {
          handleShowDialog();
        } else {
          closeDialog();
        }
      }
    );

    const addMemberVisible = computed(() => store.state.addMemberVisible);

    const {
      rightNodeKey,
      userListForName,
      filterText,
      handleNodeExpand,
      deparment,
      handleShowDialog,
      dialogVisible,
      mockData,
      handleCheckBoxEvent,
      rightCheckedNodes,
      deleteCheckedKeys,
      handleCheckBoxEventByText,
      handleConfirmSubmit,
      handleRemoveList,
      handleShowTask
    } = useGetEmployee(treeRef, keywordTreeRef, props, emit, tipMessage);
    watch(
      () => filterText.value,
      (newValue) => {
        treeRef?.value.filter(newValue);
      }
    );

    const closeDialog = () => {
      store.commit(MutationType.addMemberVisible, false);
    };

    onUnmounted(() => {
      store.commit(MutationType.addMemberVisible, false);
    });

    return {
      addMemberVisible,
      closeDialog,
      handleShowTask,
      handleRemoveList,
      handleConfirmSubmit,
      handleCheckBoxEventByText,
      keywordTreeRef,
      rightNodeKey,
      userListForName,
      handleNodeExpand,
      deparment,
      deleteCheckedKeys,
      rightCheckedNodes,
      handleCheckBoxEvent,
      filterNode,
      mockData,
      treeRef,
      filterText,
      handleShowDialog,
      dialogVisible,
      linkToPage
    };
  }
});
